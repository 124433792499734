<template>
  <section>
    <div class="content-header">
      <h2>Benefits of a positionality statement</h2>
    </div>
    <div class="content-wrapper">
      <p>Sharing a positionality statement can have a significant impact on students and on the overall learning community, including:</p>
      <ul>
        <li class="pb-2">Helping students feel more connected to and comfortable engaging with their instructor</li>
        <li class="pb-2">Modelling vulnerability, perspective taking and self-awareness as it pertains to academic discourse</li>
        <li class="pb-2">Creating space for students to discuss their own identities and experiences, and how these have influenced their perspectives and ideas related to academic material</li>
        <li>Establishing conditions that foster critical analysis on how worldviews are formed and influence our subjective experiences of academic ideas</li>
      </ul>
      <p>Feel free to start small and share what you are comfortable with, where it feels relevant and appropriate to do so. As you do this more often and witness first-hand the way it influences the learning environment, sharing your positionality with more vulnerability will feel more natural and worthwhile.</p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
